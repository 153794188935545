.header {
  z-index: 200;
  background: #fff;
  font-family: Poppins, sans-serif;
  position: fixed;
}

.header__top {
  z-index: 500;
  width: 100%;
  height: 52px;
  background: #fff;
  border-bottom: 1px solid #efefef;
  transition: all .2s;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 25px #0000001a;
}

.header__top.hide {
  top: -56px;
}

.header__bottom {
  width: 100%;
  z-index: 100;
  background: #fff;
  transition: all .2s;
  position: fixed;
  top: 52px;
}

.header__bottom.hide {
  top: 0;
  box-shadow: 0 0 5px 1px #a3a3a3;
}

.header__contact-text {
  color: #212121;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

.header__contact-text:hover, .header__contact-icon {
  color: #795548;
}

.header__menu-item {
  color: #212121;
  letter-spacing: -.7px;
  text-transform: uppercase;
  padding: 0 13px;
  font-family: montserrat, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  transition: all .3s ease-in-out;
  display: block;
  position: relative;
  overflow: hidden;
  outline: none !important;
  text-decoration: none !important;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.nav-link {
  position: relative;
}

@media (max-width: 450px) {
  .header__contact-text {
    display: none;
  }

  .header__contact-icon {
    float: left;
    padding: 8px;
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .nav a {
    padding: .5em .8em;
    text-decoration: none;
    position: relative;
  }

  .nav a:before, .nav a:after {
    content: "";
    height: 14px;
    width: 14px;
    opacity: 0;
    transition: all .35s;
    position: absolute;
  }

  .nav a:before {
    content: "";
    border-top: 3px solid #795548;
    border-right: 3px solid #795548;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
  }

  .nav a:after {
    content: "";
    border-bottom: 3px solid #795548;
    border-left: 3px solid #795548;
    bottom: 0;
    left: 0;
    transform: translate(100%, -50%);
  }

  .nav a:hover:before, .nav a:hover:after {
    opacity: 1;
    transform: translate(0);
  }

  .nav a:hover {
    color: #795548;
  }
}

@media (max-width: 991px) {
  .header__top {
    text-align: center;
  }

  .header__bottom {
    top: -350px;
  }

  .header__bottom.header__bottom--opened {
    top: 52px;
  }

  .header__contact-text {
    letter-spacing: 0;
    font-size: .8rem;
  }
}

/*# sourceMappingURL=header.4ae202db.css.map */
