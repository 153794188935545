.header {
  position: fixed;
  z-index: 200;
  background: white;
  font-family: 'Poppins', sans-serif;
}

.header__top {
  position: fixed;
  top: 0;
  z-index: 500;
  width: 100%;
  background: white;
  border-bottom: 1px solid #efefef;
  height: 52px;
  transition: all .2s ease;
  box-shadow: 0 1px 25px rgba(0,0,0, .1);
}

.header__top.hide {
  top: -56px;
}

.header__bottom {
  position: fixed;
  top: 52px;
  width: 100%;
  z-index: 100;
  background: white;

  transition: all .2s;
}

.header__bottom.hide {
  top: 0;
  -webkit-box-shadow: 0 0 5px 1px rgb(163 163 163);
  -moz-box-shadow: 0 0 5px 1px rgba(163, 163, 163, 1);
  box-shadow: 0 0 5px 1px rgb(163 163 163);
}

.header__contact-text {
  text-decoration: none;
  color: #212121;

  -webkit-transition: all 0.30s ease-in-out;
  transition: all 0.30s ease-in-out;
}

.header__contact-text:hover {
  color: #795548;
}

.header__contact-icon {
  color: #795548;
}

.header__menu-item {
  color: #212121;
  font-size: 13px;
  overflow: hidden;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.7px;
  text-transform: uppercase;
  font-family: montserrat, sans-serif;
  position: relative;
  display: block;
  padding: 0 13px;
  outline: none !important;
  text-decoration: none !important;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.nav-link {
  position: relative;
}

@media (max-width: 450px) {
  .header__contact-text {
    display: none;
  }

  .header__contact-icon {
    float: left;
    padding: 8px;
    font-size: 2rem;
  }
}


@media (min-width: 992px) {

  .nav a {
    padding: .5em .8em;
    position: relative;
    text-decoration: none;
  }

  .nav a::before,
  .nav a::after {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    transition: all .35s ease;
    opacity: 0;
  }

  .nav a::before {
    content: '';
    right: 0;
    top: 0;
    border-top: 3px solid #795548;
    border-right: 3px solid #795548;
    transform: translate(-100%, 50%);
  }

  .nav a:after {
    content: '';
    left: 0;
    bottom: 0;
    border-bottom: 3px solid #795548;
    border-left: 3px solid #795548;
    transform: translate(100%, -50%)
  }

  .nav a:hover:before,
  .nav a:hover:after{
    transform: translate(0,0);
    opacity: 1;
  }

  .nav a:hover {
    color: #795548;
  }
}

@media (max-width: 991px) {
  .header__top {
    text-align: center;
  }

  .header__bottom {
    top: -350px;
  }

  .header__bottom.header__bottom--opened {
    top: 52px;
  }

  .header__contact-text {
    font-size: 0.8rem;
    letter-spacing: 0;
  }
}
